.hero {
  background: url('../images/hero-home.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
  margin-bottom: 20px;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 120px;
    background: url('../images/fog.png') repeat-x center top;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 60;
  }

  .col-xs-12 {
    position: relative;
    padding-top: 372px;

    @media (min-width: 992px) {
      padding-top: 152px;
      height: 742px;
    }
  }
}

.hero__widget {
  position: relative;
  z-index: 80;

  @media (min-width: 992px) {
    position: absolute;
    left: 10px;
    bottom: 0;
    width: calc(100% - 20px);
  }
}
