.page-title {
  font-family: $brand-font-family;
  font-size: 27px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 26px;
  padding-right: 20px;
  padding-left: 20px;

  @media (min-width: 992px) {
    padding-right: 40px;
    padding-left: 40px;
  }
}
