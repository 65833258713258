@media print {

	.page-header{
		height: 25px;
		background: none; 
	}

	.filters{
		display: none;
	}

	.item{
		width: 100%;
		position: static !important;
		border: 1px solid #ccc;
		margin-bottom: 20px;
	}
}