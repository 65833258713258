.filters{
	margin-bottom: 25px;


	.navbar-toggle {
		background: url('../images/arrow-white.svg') no-repeat right;
		border: 1px solid transparent;
		width: 100%;
	    height: 23px;
	    padding: 0;
	    position: absolute;
	    left: 0;
	    &[aria-expanded="true"]{

	    }
	}


}
.filters__content {
  background-color: $base-color;
  color: $white-color;
  border-radius: 3px;
  padding: 20px;
}


.nav-tabs{
	margin-bottom: 50px;
	@media(max-width: 1200px){ 
		margin: 10px;
		margin-bottom: 25px;
		border-bottom: none;
		text-align: center;
	}
	.nav-item{
		@media(max-width: 1200px){ float: none; display: inline-block;}
		@media(min-width: 1200px){ height: 80px; max-width: 222px; display: table;}
		
		
		.nav-link{
			display: table-cell;
			vertical-align: middle;
			border-color: #ccc;
			@media(max-width: 1200px){
				padding: 10px;
				border-radius: 3px;
			}

			&.active{
				font-weight: bold;
				color: $base-color;
				border-bottom: none;
				@media(max-width: 1200px){
					background-color: $link-color;
					color: $white-color;
				 }
			}

		}
	}
}



