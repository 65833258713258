body {
  font-family: $base-font-family;
  color: $base-color;
  font-size: 15px;
  line-height: 25px;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.content a {
  border-bottom: 1px solid rgba($brand-primary, 0.5);
  color: $brand-primary;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $brand-primary;
    text-decoration: none;
    border-bottom-color: rgba($brand-primary, 0.8);
  }
}


.arrow{
  font-family: serif;
}