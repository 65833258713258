.news-widget {
  background-color: $base-color;
  color: $white-color;
  padding: 20px;
  border-radius: 3px;

  @media (min-width: 992px) {
    padding-right: 40px;
    padding-left: 40px;
  }
}

.news-widget__type {
  text-transform: uppercase;
  margin-bottom: 10px;
}

.news-widget__title {
  font-size: 27px;
  line-height: 32px;
  color: rgba($brand-primary, 0.8);
  font-family: $brand-font-family;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 1px solid rgba($brand-primary, 0.5%);

  &:hover,
  &:focus {
    color: rgba($brand-primary, 0.8);
    border-bottom-color: rgba($brand-primary, 0.8%);
    text-decoration: none;
  }
}

.news-widget__date {
  color: #616161;
  margin-top: 10px;
}
