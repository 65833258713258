.page-header {
  background: url('../images/hero.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
  height: 350px;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 120px;
    background: url('../images/fog.png') repeat-x center top;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 60;
  }
}
