.facets {

}

.facets__title {
  font-weight: 700;
  position: relative;

  &:not(:first-child) {
    margin-top: 25px;
  }
}

.facets__list {
  margin: 0;
  padding: 0;
  list-style: none;

  @media(min-width: 992px){
    &.collapse{
      display: block;
      height: auto !important;
    }
  }

  li {
    margin: 0;
    padding: 0;
  }

  label {
    color: #c8c8c8;
    margin: 0;
    font-size: 0.9em;
  }

  input[type='checkbox'] {
    display: inline-block;
    margin-right: 8px;
    position: relative;
    top: -1px;
  }



}
