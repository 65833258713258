// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.


// Table of Contents
//
// Colors
// Options
// Spacing
// Body
// Links
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components

@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.


// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$gray-dark:                 #373a3c !default;
$gray:                      #55595c !default;
$gray-light:                #818a91 !default;
$gray-lighter:              #eceeef !default;
$gray-lightest:             #f7f7f9 !default;

$brand-primary:             #0275d8 !default;
$brand-success:             #5cb85c !default;
$brand-info:                #5bc0de !default;
$brand-warning:             #f0ad4e !default;
$brand-danger:              #d9534f !default;
$brand-inverse:             $gray-dark !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-flex:               false !default;
$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        false !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer:   1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: $spacer-x,
    y: $spacer-y
  ),
  2: (
    x: ($spacer-x * 1.5),
    y: ($spacer-y * 1.5)
  ),
  3: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  )
) !default;
$border-width: 1px !default;


// Body
//
// Settings for the `<body>` element.

$body-bg:    #fff !default;
$body-color: $gray-dark !default;


// Links
//
// Style anchor elements.

$link-color:            $brand-primary !default;
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1140px
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:      12 !default;
$grid-gutter-width: 30px !default;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root: 16px !default;

$font-size-base: 1rem !default;
$font-size-lg:   1.25rem !default;
$font-size-sm:   .875rem !default;
$font-size-xs:   .75rem !default;

$line-height-base: 1.5 !default;

$font-size-h1: 2.5rem !default;
$font-size-h2: 2rem !default;
$font-size-h3: 1.75rem !default;
$font-size-h4: 1.5rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family:   inherit !default;
$headings-font-weight:   500 !default;
$headings-line-height:   1.1 !default;
$headings-color:         inherit !default;

$lead-font-size:   1.25rem !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-light !default;

$abbr-border-color: $gray-light !default;

$blockquote-small-color:  $gray-light !default;
$blockquote-font-size:    ($font-size-base * 1.25) !default;
$blockquote-border-color: $gray-lighter !default;
$blockquote-border-width: .25rem !default;

$hr-border-color: rgba(0,0,0,.1) !default;
$hr-border-width: $border-width !default;

$mark-padding: .2em !default;

$dt-font-weight: bold !default;

$kbd-box-shadow:         inset 0 -.1rem 0 rgba(0,0,0,.25) !default;
$nested-kbd-font-weight: bold !default;

$list-inline-padding: 5px !default;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:         (4 / 3) !default;
$line-height-sm:         1.5 !default;

$border-radius:          .25rem !default;
$border-radius-lg:       .3rem !default;
$border-radius-sm:       .2rem !default;

$component-active-color: #fff !default;
$component-active-bg:    $brand-primary !default;

$caret-width:            .3em !default;
$caret-width-lg:         $caret-width !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .75rem !default;
$table-sm-cell-padding:         .3rem !default;

$table-bg:                      transparent !default;
$table-bg-accent:               rgba(0,0,0,.05) !default;
$table-bg-hover:                rgba(0,0,0,.075) !default;
$table-bg-active:               $table-bg-hover !default;

$table-border-width:            $border-width !default;
$table-border-color:            $gray-lighter !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-padding-x:                  1rem !default;
$btn-padding-y:                  .5rem !default;
$btn-line-height:                1.25 !default;
$btn-font-weight:                normal !default;
$btn-box-shadow:                 inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075) !default;
$btn-active-box-shadow:          inset 0 3px 5px rgba(0,0,0,.125) !default;

$btn-primary-color:              #fff !default;
$btn-primary-bg:                 $brand-primary !default;
$btn-primary-border:             $btn-primary-bg !default;

$btn-secondary-color:            $gray-dark !default;
$btn-secondary-bg:               #fff !default;
$btn-secondary-border:           #ccc !default;

$btn-info-color:                 #fff !default;
$btn-info-bg:                    $brand-info !default;
$btn-info-border:                $btn-info-bg !default;

$btn-success-color:              #fff !default;
$btn-success-bg:                 $brand-success !default;
$btn-success-border:             $btn-success-bg !default;

$btn-warning-color:              #fff !default;
$btn-warning-bg:                 $brand-warning !default;
$btn-warning-border:             $btn-warning-bg !default;

$btn-danger-color:               #fff !default;
$btn-danger-bg:                  $brand-danger !default;
$btn-danger-border:              $btn-danger-bg !default;

$btn-link-disabled-color:        $gray-light !default;

$btn-padding-x-sm:               .5rem !default;
$btn-padding-y-sm:               .25rem !default;

$btn-padding-x-lg:               1.5rem !default;
$btn-padding-y-lg:               .75rem !default;

$btn-block-spacing-y:            .5rem !default;
$btn-toolbar-margin:             .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:              $border-radius !default;
$btn-border-radius-lg:           $border-radius-lg !default;
$btn-border-radius-sm:           $border-radius-sm !default;


// Forms

$input-padding-x:                .75rem !default;
$input-padding-y:                .5rem !default;
$input-line-height:              1.25 !default;

$input-bg:                       #fff !default;
$input-bg-disabled:              $gray-lighter !default;

$input-color:                    $gray !default;
$input-border-color:             rgba(0,0,0,.15) !default;
$input-btn-border-width:         $border-width !default; // For form controls and buttons
$input-box-shadow:               inset 0 1px 1px rgba(0,0,0,.075) !default;

$input-border-radius:            $border-radius !default;
$input-border-radius-lg:         $border-radius-lg !default;
$input-border-radius-sm:         $border-radius-sm !default;

$input-bg-focus:                 $input-bg;
$input-border-focus:             #66afe9 !default;
$input-box-shadow-focus:         rgba(102,175,233,.6) !default;
$input-color-focus:              $input-color;

$input-color-placeholder:        #999 !default;

$input-padding-x-sm:             .5rem !default;
$input-padding-y-sm:             .25rem !default;

$input-padding-x-lg:             1.5rem !default;
$input-padding-y-lg:             .75rem !default;

$input-height:                   (($font-size-base * $line-height-base) + ($input-padding-y * 2)) !default;
$input-height-lg:                (($font-size-lg * $line-height-lg) + ($input-padding-y-lg * 2)) !default;
$input-height-sm:                (($font-size-sm * $line-height-sm) + ($input-padding-y-sm * 2)) !default;

$form-group-margin-bottom:       $spacer-y !default;

$input-group-addon-bg:           $gray-lighter !default;
$input-group-addon-border-color: $input-border-color !default;

$cursor-disabled:                not-allowed !default;

$custom-control-gutter:   1.5rem !default;
$custom-control-spacer-x: 1rem !default;
$custom-control-spacer-y: .25rem !default;

$custom-control-indicator-size:       1rem !default;
$custom-control-indicator-bg:         #ddd !default;
$custom-control-indicator-bg-size:    50% 50% !default;
$custom-control-indicator-box-shadow: inset 0 .25rem .25rem rgba(0,0,0,.1) !default;

$custom-control-disabled-cursor:             $cursor-disabled !default;
$custom-control-disabled-indicator-bg:       #eee !default;
$custom-control-disabled-description-color:  #767676 !default;

$custom-control-checked-indicator-color:      #fff !default;
$custom-control-checked-indicator-bg:         #0074d9 !default;
$custom-control-checked-indicator-box-shadow: none !default;

$custom-control-focus-indicator-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9 !default;

$custom-control-active-indicator-color:      #fff !default;
$custom-control-active-indicator-bg:         #84c6ff !default;
$custom-control-active-indicator-box-shadow: none !default;

$custom-checkbox-radius: $border-radius !default;
$custom-checkbox-checked-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !default;
$custom-checkbox-indeterminate-bg: #0074d9 !default;
$custom-checkbox-indeterminate-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E") !default;
$custom-checkbox-indeterminate-box-shadow: none !default;

$custom-radio-radius: 50% !default;
$custom-radio-checked-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E") !default;

$custom-select-padding-x:          .75rem  !default;
$custom-select-padding-y:          .375rem !default;
$custom-select-indicator-padding:  1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-color:          $input-color !default;
$custom-select-disabled-color: $gray-light !default;
$custom-select-bg:            #fff !default;
$custom-select-disabled-bg:   $gray-lighter !default;
$custom-select-bg-size:       8px 10px !default; // In pixels because image dimensions
$custom-select-indicator:     url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") !default;
$custom-select-border-width:  $input-btn-border-width !default;
$custom-select-border-color:  $input-border-color !default;
$custom-select-border-radius: $border-radius !default;

$custom-select-focus-border-color: #51a7e8 !default;
$custom-select-focus-box-shadow:   inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 5px rgba(81, 167, 232, .5) !default;

$custom-select-sm-padding-y: .2rem !default;
$custom-select-sm-font-size: 75% !default;

$custom-file-height:           2.5rem !default;
$custom-file-width:            14rem !default;
$custom-file-focus-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9 !default;

$custom-file-padding-x:     .5rem !default;
$custom-file-padding-y:     1rem !default;
$custom-file-line-height:   1.5 !default;
$custom-file-color:         #555 !default;
$custom-file-bg:            #fff !default;
$custom-file-border-width:  $border-width !default;
$custom-file-border-color:  #ddd !default;
$custom-file-border-radius: $border-radius !default;
$custom-file-box-shadow:    inset 0 .2rem .4rem rgba(0,0,0,.05) !default;
$custom-file-button-color:  $custom-file-color !default;
$custom-file-button-bg:     #eee !default;
$custom-file-text: (
  placeholder: (
    en: "Choose file..."
  ),
  button-label: (
    en: "Browse"
  )
) !default;


// Form validation icons
$form-icon-success: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") !default;
$form-icon-warning: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23f0ad4e' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E") !default;
$form-icon-danger: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23d9534f' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E") !default;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:             160px !default;
$dropdown-padding-y:             5px !default;
$dropdown-margin-top:            2px !default;
$dropdown-bg:                    #fff !default;
$dropdown-border-color:          rgba(0,0,0,.15) !default;
$dropdown-border-width:          $border-width !default;
$dropdown-divider-bg:            #e5e5e5 !default;
$dropdown-box-shadow:            0 6px 12px rgba(0,0,0,.175) !default;

$dropdown-link-color:            $gray-dark !default;
$dropdown-link-hover-color:      darken($gray-dark, 5%) !default;
$dropdown-link-hover-bg:         #f5f5f5 !default;

$dropdown-link-active-color:     $component-active-color !default;
$dropdown-link-active-bg:        $component-active-bg !default;

$dropdown-link-disabled-color:   $gray-light !default;

$dropdown-item-padding-x:        20px !default;

$dropdown-header-color:          $gray-light !default;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown-backdrop:  990 !default;
$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-navbar-fixed:      1030 !default;
$zindex-navbar-sticky:     1030 !default;
$zindex-modal-bg:          1040 !default;
$zindex-modal:             1050 !default;


// Navbar

$navbar-border-radius:              $border-radius !default;
$navbar-padding-x:                  $spacer !default;
$navbar-padding-y:                  ($spacer / 2) !default;
$navbar-brand-padding-y:            .25rem !default;

$navbar-dark-color:                 rgba(255,255,255,.5) !default;
$navbar-dark-hover-color:           rgba(255,255,255,.75) !default;
$navbar-dark-active-color:          rgba(255,255,255,1) !default;
$navbar-dark-disabled-color:        rgba(255,255,255,.25) !default;

$navbar-light-color:                rgba(0,0,0,.3) !default;
$navbar-light-hover-color:          rgba(0,0,0,.6) !default;
$navbar-light-active-color:         rgba(0,0,0,.8) !default;
$navbar-light-disabled-color:       rgba(0,0,0,.15) !default;


// Navs

$nav-item-margin:               .2rem !default;
$nav-item-inline-spacer:        1rem !default;
$nav-link-padding:              .5em 1em !default;
$nav-link-hover-bg:             $gray-lighter !default;
$nav-disabled-link-color:       $gray-light !default;
$nav-disabled-link-hover-color: $gray-light !default;
$nav-disabled-link-hover-bg:    transparent !default;

$nav-tabs-border-color:                       #ddd !default;
$nav-tabs-border-width:                       $border-width !default;
$nav-tabs-border-radius:                      $border-radius !default;
$nav-tabs-link-hover-border-color:            $gray-lighter !default;
$nav-tabs-active-link-hover-color:            $gray !default;
$nav-tabs-active-link-hover-bg:               $body-bg !default;
$nav-tabs-active-link-hover-border-color:     #ddd !default;
$nav-tabs-justified-link-border-color:        #ddd !default;
$nav-tabs-justified-active-link-border-color: $body-bg !default;

$nav-pills-border-radius:     $border-radius !default;
$nav-pills-active-link-color: $component-active-color !default;
$nav-pills-active-link-bg:    $component-active-bg !default;


// Pagination

$pagination-padding-x:                .75rem !default;
$pagination-padding-y:                .5rem !default;
$pagination-padding-x-sm:             .75rem !default;
$pagination-padding-y-sm:             .275rem !default;
$pagination-padding-x-lg:             1.5rem !default;
$pagination-padding-y-lg:             .75rem !default;


$pagination-color:                     $link-color !default;
$pagination-bg:                        #fff !default;
$pagination-border-width:              $border-width !default;
$pagination-border-color:              #ddd !default;

$pagination-hover-color:               $link-hover-color !default;
$pagination-hover-bg:                  $gray-lighter !default;
$pagination-hover-border:              #ddd !default;

$pagination-active-color:              #fff !default;
$pagination-active-bg:                 $brand-primary !default;
$pagination-active-border:             $brand-primary !default;

$pagination-disabled-color:            $gray-light !default;
$pagination-disabled-bg:               #fff !default;
$pagination-disabled-border:           #ddd !default;


// Jumbotron

$jumbotron-padding:              2rem !default;
$jumbotron-bg:                   $gray-lighter !default;


// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.

$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken($state-success-bg, 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken($state-info-bg, 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$mark-bg:                        $state-warning-bg !default;
$state-warning-border:           darken($state-warning-bg, 5%) !default;

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken($state-danger-bg, 5%) !default;


// Cards
$card-spacer-x:            1.25rem !default;
$card-spacer-y:            .75rem !default;
$card-border-width:        1px !default;
$card-border-radius:       $border-radius !default;
$card-border-color:        rgba(0,0,0,.125) !default;
$card-border-radius-inner: $card-border-radius !default;
$card-cap-bg:              #f5f5f5 !default;
$card-bg:                  #fff !default;

$card-link-hover-color:    #fff !default;

$card-img-overlay-padding: 1.25rem !default;

$card-deck-margin:         .625rem !default;

$card-columns-sm-up-column-gap: 1.25rem !default;


// Tooltips

$tooltip-max-width:           200px !default;
$tooltip-color:               #fff !default;
$tooltip-bg:                  #000 !default;
$tooltip-opacity:             .9 !default;
$tooltip-padding-y:           3px !default;
$tooltip-padding-x:           8px !default;
$tooltip-margin:              3px !default;

$tooltip-arrow-width:         5px !default;
$tooltip-arrow-color:         $tooltip-bg !default;


// Popovers

$popover-inner-padding:               1px !default;
$popover-bg:                          #fff !default;
$popover-max-width:                   276px !default;
$popover-border-width:                $border-width !default;
$popover-border-color:                rgba(0,0,0,.2) !default;
$popover-box-shadow:                  0 5px 10px rgba(0,0,0,.2) !default;

$popover-title-bg:                    darken($popover-bg, 3%) !default;
$popover-title-padding-x:             14px !default;
$popover-title-padding-y:             8px !default;

$popover-content-padding-x:           14px !default;
$popover-content-padding-y:           9px !default;

$popover-arrow-width:                 10px !default;
$popover-arrow-color:                 $popover-bg !default;

$popover-arrow-outer-width:           ($popover-arrow-width + 1px) !default;
$popover-arrow-outer-color:           fade-in($popover-border-color, .05) !default;


// Tags

$tag-default-bg:            $gray-light !default;
$tag-primary-bg:            $brand-primary !default;
$tag-success-bg:            $brand-success !default;
$tag-info-bg:               $brand-info !default;
$tag-warning-bg:            $brand-warning !default;
$tag-danger-bg:             $brand-danger !default;

$tag-color:                 #fff !default;
$tag-link-hover-color:      #fff !default;
$tag-font-size:             75% !default;
$tag-font-weight:           bold !default;
$tag-padding-x:             .4em !default;
$tag-padding-y:             .25em !default;

$tag-pill-padding-x:        .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$tag-pill-border-radius:    10rem !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding:         15px !default;

$modal-dialog-margin:         10px !default;
$modal-dialog-sm-up-margin-y: 30px !default;

$modal-title-padding:         15px !default;
$modal-title-line-height:     $line-height-base !default;

$modal-content-bg:               #fff !default;
$modal-content-border-color:     rgba(0,0,0,.2) !default;
$modal-content-border-width:     $border-width !default;
$modal-content-xs-box-shadow:    0 3px 9px rgba(0,0,0,.5) !default;
$modal-content-sm-up-box-shadow: 0 5px 15px rgba(0,0,0,.5) !default;

$modal-backdrop-bg:           #000 !default;
$modal-backdrop-opacity:      .5 !default;
$modal-header-border-color:   #e5e5e5 !default;
$modal-footer-border-color:   $modal-header-border-color !default;
$modal-header-border-width:   $modal-content-border-width !default;
$modal-footer-border-width:   $modal-header-border-width !default;

$modal-lg:                    900px !default;
$modal-md:                    600px !default;
$modal-sm:                    300px !default;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding:               1rem !default;
$alert-border-radius:         $border-radius !default;
$alert-link-font-weight:      bold !default;
$alert-border-width:          $border-width !default;

$alert-success-bg:            $state-success-bg !default;
$alert-success-text:          $state-success-text !default;
$alert-success-border:        $state-success-border !default;

$alert-info-bg:               $state-info-bg !default;
$alert-info-text:             $state-info-text !default;
$alert-info-border:           $state-info-border !default;

$alert-warning-bg:            $state-warning-bg !default;
$alert-warning-text:          $state-warning-text !default;
$alert-warning-border:        $state-warning-border !default;

$alert-danger-bg:             $state-danger-bg !default;
$alert-danger-text:           $state-danger-text !default;
$alert-danger-border:         $state-danger-border !default;


// Progress bars

$progress-bg:                 #eee !default;
$progress-bar-color:          #0074d9 !default;
$progress-border-radius:      $border-radius !default;
$progress-box-shadow:         inset 0 .1rem .1rem rgba(0,0,0,.1) !default;

$progress-bar-bg:             $brand-primary !default;
$progress-bar-success-bg:     $brand-success !default;
$progress-bar-warning-bg:     $brand-warning !default;
$progress-bar-danger-bg:      $brand-danger !default;
$progress-bar-info-bg:        $brand-info !default;


// List group

$list-group-bg:                 #fff !default;
$list-group-border-color:       #ddd !default;
$list-group-border-width:       $border-width !default;
$list-group-border-radius:      $border-radius !default;

$list-group-hover-bg:           #f5f5f5 !default;
$list-group-active-color:       $component-active-color !default;
$list-group-active-bg:          $component-active-bg !default;
$list-group-active-border:      $list-group-active-bg !default;
$list-group-active-text-color:  lighten($list-group-active-bg, 40%) !default;

$list-group-disabled-color:      $gray-light !default;
$list-group-disabled-bg:         $gray-lighter !default;
$list-group-disabled-text-color: $list-group-disabled-color !default;

$list-group-link-color:         #555 !default;
$list-group-link-hover-color:   $list-group-link-color !default;
$list-group-link-heading-color: #333 !default;

$list-group-item-padding-x:             1.25rem !default;
$list-group-item-padding-y:             .75rem !default;
$list-group-item-heading-margin-bottom: 5px !default;


// Image thumbnails

$thumbnail-padding:           .25rem !default;
$thumbnail-bg:                $body-bg !default;
$thumbnail-border-width:      $border-width !default;
$thumbnail-border-color:      #ddd !default;
$thumbnail-border-radius:     $border-radius !default;
$thumbnail-box-shadow:        0 1px 2px rgba(0,0,0,.075) !default;


// Figures

$figure-caption-font-size: 90% !default;


// Breadcrumbs

$breadcrumb-padding-y:          .75rem !default;
$breadcrumb-padding-x:          1rem !default;
$breadcrumb-item-padding:       .5rem !default;

$breadcrumb-bg:                 $gray-lighter !default;
$breadcrumb-divider-color:      $gray-light !default;
$breadcrumb-active-color:       $gray-light !default;
$breadcrumb-divider:            "/" !default;


// Media objects

$media-margin-top:            15px !default;
$media-heading-margin-bottom:  5px !default;
$media-alignment-padding-x:   10px !default;


// Carousel

$carousel-text-shadow:                        0 1px 2px rgba(0,0,0,.6) !default;

$carousel-control-color:                      #fff !default;
$carousel-control-width:                      15% !default;
$carousel-control-sm-up-size:                 30px !default;
$carousel-control-opacity:                    .5 !default;
$carousel-control-font-size:                  20px !default;

$carousel-indicators-width:                   60% !default;

$carousel-indicator-size:                     10px !default;
$carousel-indicator-active-size:              12px !default;
$carousel-indicator-active-bg:                #fff !default;
$carousel-indicator-border-color:             #fff !default;

$carousel-caption-width:                      70% !default;
$carousel-caption-sm-up-width:                60% !default;
$carousel-caption-color:                      #fff !default;

$carousel-icon-width:                         20px !default;


// Close

$close-font-weight:           bold !default;
$close-color:                 #000 !default;
$close-text-shadow:           0 1px 0 #fff !default;


// Code

$code-font-size:              90% !default;
$code-padding-x:              .4rem !default;
$code-padding-y:              .2rem !default;
$code-color:                  #bd4147 !default;
$code-bg:                     #f7f7f9 !default;

$kbd-color:                   #fff !default;
$kbd-bg:                      #333 !default;

$pre-bg:                      #f7f7f9 !default;
$pre-color:                   $gray-dark !default;
$pre-border-color:            #ccc !default;
$pre-scrollable-max-height:   340px !default;
