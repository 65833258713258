.header {
  width: 100%;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;

  .navbar {
    width: 100%;
    background-color: $white-color;
    border-radius: 3px;
    padding: 0;
    box-shadow: 0 0 5px 0 rgba(#2a1414, 0.8);

    @media (min-width: 992px) {
      box-shadow: none;
      height: 110px;
    }
  }

  .navbar-collapse {
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
  }

  .nav-header {
    position: relative;
  }

  .navbar-toggle {
    background-color: $brand-primary;
    position: absolute;
    right: 20px;
    top: 70%;
    @media (min-width: 544px) { top: 65%; }
    transform: translateY(-50%);
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 4px;

    .icon-bar {
      display: block;
      width: 22px;
      background-color: #fff;
      height: 3px;
      margin-bottom: 4px;
      border-radius: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .brand {
    display: inline-block;
    padding: 29px 20px 22px 20px;

    &:hover {
      text-decoration: none;
    }
  }
}

#main-nav,
#upper-nav {
  a {
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
  }
}

#main-nav {
  @media (min-width: 768px) {
    border-top: 1px solid #d3d3d3;
    padding: 13px 0;
    margin: 0 20px;
  }

  @media (min-width: 992px) {
    position: absolute;
    bottom: 20px;
    right: 25px;
    margin: 0;
    padding-bottom: 0;
  }

  li {
    @media (min-width: 768px) {
      display: inline-block;
      margin: 0 10px 0 0;
    }
    @media (min-width: 992px) {
      display: inline-block;
      margin: 0 15px 0 0;
    }
    @media (min-width: 1200px) {
      margin: 0 35px 0 0;
    }
    &:last-child {
      margin-right: 0;
    }
    a {
      color: $base-color;

      @media (min-width: 768px) {
        padding: 0;

        &:hover {
          background-color: transparent;
        }
      }
    }

    &.active {
      a {
        color: $brand-primary;
      }
    }
  }
}

#upper-nav {
  @media (min-width: 768px) {
    position: absolute;
    top: 63px;
    right: 25px;
  }
  @media (min-width: 992px) {
    top: 20px;
  }
  li {
    @media (min-width: 768px) {
      display: inline-block;
      margin: 0 30px 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
    &.active {
      a {
        color: $brand-primary;
      }
    }
    a {
      font-size: 14px;
      color: $grey-color;
    }
  }
}

.extra-info {
  @media (max-width: 768px) {
    text-align: center;
    padding: 15px;
    border-bottom: 1px solid #ccc;
    margin: 0 25px;
  }
  a{
    color: $grey-color;
    &:hover{
      color: #666;
    }
  }
  .tel{
    background: url('../images/tel.png') no-repeat left center;
    background-size: 13px;
    padding-left: 17px; 
  }
  .loc{
    @media (max-width: 544px) { display: block; }
    @media (min-width: 544px) { margin-left: 10px; }
    &:before{
      content: '';
      display: inline-block;
      background: url('../images/loc.png') no-repeat left center;
      background-size: 13px;
      width: 17px; height: 13px;
      margin-bottom: -1px;
    }
  }

  @media (min-width: 768px) {
    position: absolute;
    right: 20px;
    top: 22px;
    text-transform: uppercase;
    color: $grey-color;
  }
  @media (min-width: 992px) {
    left: 0;
    right: auto;
    top: -30px;
  }
}


#menu-main,
#menu-upper {
  a {
    color: $base-color;
    text-decoration: none;
    padding: 5px 0;
    display: block;
    &:hover {
      text-decoration: none;
    }
  }

  li {
    border-bottom: 1px solid #ccc;
    &.active {
      a {
        color: $brand-primary;
      }
    }
  }
}


@media (min-width: 768px) {
.gift-box{

  .brand{
    &:before{
      content: '';
      position: absolute;
      width: 109px;
      height: 113px;
      background: url(../images/gold-bow.png);
      background-size: 122px;
      top: -1px;
      left: 40px;
    }
  }
}

}