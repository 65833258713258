.btn-primary{
	border: 1px solid #8f7841;
	color: #fff;
}
.btn-secondary{
	border-color: $brand-primary;
	color: $brand-primary;
}

.content{
	.btn-primary{
		color: #fff;
		border: 1px solid #8f7841;
		&:hover{
			color: #fff;
		}
	}
	.btn-secondary{
		border-color: $brand-primary;
		color: $brand-primary;
	}
}


.append{
	margin-top: 25px;
	padding-right: 0;
}