.intro {
  margin-bottom: 25px;
  padding-right: 20px;
  padding-left: 20px;

  @media (min-width: 992px) {
    margin-bottom: 45px;
    padding-right: 20px;
    padding-left: 20px;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

h1, h2{
  font-family: $brand-font-family;
  text-transform: uppercase;
  font-size: 2.3em;
}
h2{
  font-size: 2em;
  font-weight: 100;
}