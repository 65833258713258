.column-title {
  font-size: 23px;
  color: #616161;
  font-family: $brand-font-family;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;

  @media (min-width: 992px) {
    margin-top: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
}
