.page .content {
  background: url('../images/fog-bottom.png') no-repeat center bottom;
  padding-bottom: 100px;
}


#map-canvas{
	height: 500px;
	border: 1px solid #ccc;
	border-radius: 3px;
}