@media (min-width: 992px) {
  .home-cta {
    .col-lg-4:nth-child(2) {
      .cta-content {
        position: relative;

        &::before,
        &::after {
          content: '';
          width: 1px;
          height: 200px;
          background-color: #d3d3d3;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        &::before {
          left: -10px;
        }

        &::after {
          right: -10px;
        }
      }
    }
  }
}

.cta__image {
  border-radius: 3px;
}

.cta-content {
  padding: 20px 0;

  @media (min-width: 1200px) {
    padding-right: 40px;
    padding-left: 40px;
  }
}

.cta{
  h2 {
    font-size: 1.5em;
    font-family: $brand-font-family;
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 400;
    text-transform: uppercase;
    @media (min-width: 992px){
      font-size: 27px;
    }
    a {
      color: $base-color;
      text-decoration: none;
      border-bottom: 1px solid rgba($brand-primary, 0.5);
      text-transform: uppercase;

      &:hover {
        border-bottom-color: rgba($brand-primary, 0.8);
        text-decoration: none;
      }
    }
  }
}

.gift{
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  h2{
    top: 45%; left: 0; right: 0;
    position: absolute;
    a{
      background: $white-color;
      padding: 10px;
      border-radius: 3px;
      border: none;
    }
  }
}

.reserveer, .opening{
  background: $black-color;
  color: $white-color;
  border-radius: 3px;
  padding-left: 20px;
  padding-right: 20px;
  @media (min-width: 1200px) {
    padding: 0;
  }
  h2{
    color: $link-color;
    a{
      color: $link-color;
      font-weight: 500;
    }
  }
  .cta__text{
    padding-right: 30px; 
    @media (min-width: 544px){
      padding-right: 60px; 
    }
  }
  .cta_img{
    /*position: absolute;

    top: -3px;
    left: -17px;
    width: 135%;

    @media (min-width: 768px){
      top: -30px;
      left: -30px;
      width: 115%;
    }
    @media (min-width: 992px){
      width: 135%;
    }

    @media (min-width: 1200px){
      top: -30px;
      left: -15px;
      width: 115%;

    }
  
    */
    img{
      /*-ms-transform: rotate(2deg); 
      -webkit-transform: rotate(2deg); 
      transform: rotate(2deg);*/
      border: 4px solid;
      background: $brand-primary;
      box-shadow: 0 0 15px rgba(0,0,0,0.3);
    } 
  }
}

.cta__text {
  a {
    font-weight: 700;
    border-bottom: 1px solid rgba($brand-primary, 0.5);
    &:hover {
      border-bottom-color: rgba($brand-primary, 0.8);
      text-decoration: none;
    }
  }
}

.opening{
  padding: 20px;
  margin-left: 10px;
  @media(max-width: 768px){
    margin-top: 25px;

  }
}
