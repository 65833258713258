// Grid settings
$enable-flex: false;
$grid-gutter-width: 20px;

$logo: '../images/logo_adelante.png';

// Colors
$white-color: #fff;
$black-color: #000;
$brand-primary: #bb9d58;
$base-color: #111;
$grey-color: #a7a7a7;
$link-color: $brand-primary;

// Fonts
$base-font-family: 'Open Sans', sans-serif;
$brand-font-family: 'Times', serif;
