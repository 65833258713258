.footer {
  color: $white-color;
  font-size: 15px;
  line-height: 20px;
}

.footer__primary {
  background-color: $base-color;
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer__secondary {
  background-color: $black-color;
  padding-top: 20px;
  padding-bottom: 20px;
}
