.tile {
  font-size: 15px;
  line-height: 20px;
  box-shadow: 0 0 5px 0 rgba(#2a1414, 0.8);
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 0;
  .tile_content{
    padding: 20px;
    position: relative;
    overflow: hidden;
  }
  .tile__image{
    margin-bottom: 0
  }

  p{
    margin-right: 60px;
  }
  .btn{
    margin-bottom: 5px;
  }
  @media (min-width: 992px) {
    
  }
}

.single-arrangement{
  .tile{
    padding-right: 170px;
    .tile__title{
      margin-bottom: 25px;
    }
    .tile_info{
      p{
        margin-bottom: 25px;
      }
      ul{
        margin-bottom: 25px;
      }
    }
  }
}

.arr{

}
@media (min-width: 1200px) {
  .arr{
    .tile__title { font-size: 27px; line-height: 32px;}
  }
}

.tile__title {
  font-size: 21px;
  line-height: 24px;
  font-family: $brand-font-family;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
  margin-right: 60px;


  a {
    color: $brand-primary;
    text-decoration: none;
    border-bottom: 1px solid rgba($brand-primary, 0.5);

    &:hover,
    &:focus {
      border-bottom-color: rgba($brand-primary, 0.8);
    }
  }
}

.tile__label {
  position: absolute;
  right: -23px;
  top: -11px;
  width: 96px;
  height: 96px;
  background: $brand-primary url('../images/pers.svg') no-repeat 30px 20px;
  background-size: 20px 22px;
  border-radius: 100%;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding: 45px 25px 0 8px;
  font-weight: 300;
  //z-index: -1;
  &.duo{
    background: $brand-primary url('../images/duo.svg') no-repeat 20px 20px;
    background-size: 42px 22px;
  }
  &.big{
    width: 165px; height: 165px;
    right: -30px;
    top: -20px;
    background-position: 42px 40px;
    background-size: 64px 32px;
    font-size: 15px;
    padding-top: 80px;
    .tile__label__price{
      font-size: 30px;
      line-height: 30px;
    }
  }
}

.tile__label__price {
  display: block;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
}

.tile_info {
  p{
    margin-bottom: 5px;
  }
  ul {
    color: #616161;
    margin: 0 0 10px 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 5px 0;
      border-top: 1px solid rgba($black-color, 0.2);

      &:last-child {
        border-bottom: 1px solid rgba($black-color, 0.2);
      }
    }
  }

}

.tile__image{
  margin-bottom: 15px;
  border-radius: 3px;
}
.tile__link {
  font-weight: 700;
}

.sub_term{
  h2{
    margin-bottom: 0;
    margin-top: 20px;
  }
  margin-bottom: 20px;
}
