.footer{
	@media (max-width: 768px) {
		text-align: center;
	}
}
.footer-title {
  margin-top: 0;
  margin-bottom: 0;
  color: $white-color;
  font-size: 15px;
  line-height: 20px;
}


.footer-icon{

	background: url('../images/adelante-icon.png') no-repeat top center;
	padding-top: 80px;

	@media (min-width: 768px) {
		padding-left: 100px;
		padding-top: 0;
		background-position: left;
	}

}

.footer-nav{
	padding-left: 0;
	li{
		display: inline-block;
	}
}

@media (min-width: 768px) {
	.footer-nav>li+li:before{
		content: "- ";
		color: $white-color;
	}
}

.subscribe{
  margin: 20px auto;
  @media (min-width: 992px) {
  	margin-top: 0;
  }
  input{
    padding: 16px;
    padding-top: 10px;
    width: 100%;
    margin: 0;
    background: #222222;
    height: 35px;
    border: 1px solid #525252;
    color: $white-color;
    @media (min-width: 768px) {
		width: 60%;
	}
    &[type="submit"]{
		background: $brand-primary;
		border-top: 0;
		vertical-align: bottom;
		text-align: center;
		border-radius: 3px;
		line-height: 0.85;
	  	@media (min-width: 768px) {
			width: 35%;
			margin-left: -3px;
		}
    }
  }
}